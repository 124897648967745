// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-results-js": () => import("../src/templates/results.js" /* webpackChunkName: "component---src-templates-results-js" */),
  "component---src-templates-town-js": () => import("../src/templates/town.js" /* webpackChunkName: "component---src-templates-town-js" */),
  "component---src-templates-post-js": () => import("../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-blog-post-js": () => import("../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-index-js": () => import("../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-404-js": () => import("../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-pages-blog-js": () => import("../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-callback-js": () => import("../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-cennik-js": () => import("../src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-dodaj-oferte-js": () => import("../src/pages/dodaj-oferte.js" /* webpackChunkName: "component---src-pages-dodaj-oferte-js" */),
  "component---src-pages-dodaj-oferte-dziekujemy-js": () => import("../src/pages/dodaj-oferte/dziekujemy.js" /* webpackChunkName: "component---src-pages-dodaj-oferte-dziekujemy-js" */),
  "component---src-pages-edytuj-oferte-js": () => import("../src/pages/edytuj-oferte.js" /* webpackChunkName: "component---src-pages-edytuj-oferte-js" */),
  "component---src-pages-miasta-js": () => import("../src/pages/miasta.js" /* webpackChunkName: "component---src-pages-miasta-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-profil-js": () => import("../src/pages/profil.js" /* webpackChunkName: "component---src-pages-profil-js" */),
  "component---src-pages-regulamin-js": () => import("../src/pages/regulamin.js" /* webpackChunkName: "component---src-pages-regulamin-js" */)
}

