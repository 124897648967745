module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Weselnicy.pl","short_name":"Weselnicy.pl","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"standalone","icon":"static/icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-142156426-1","head":false,"anonymize":true,"respectDNT":true},
    }]
